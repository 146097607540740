import howtoImage from '../image/taptapenglish_howto_800.jpg';
import { FiX } from 'react-icons/fi';

export default function HowtoScreen({ className, onHowtoScreen = (f) => f }) {
  return (
    <>
      <div className={[className, 'z-10 bg-white absolute top-0 left-0 right-0 bottom-0 pt-28 px-8'].join(' ')}>
        <div className="text-right w-full">
          <button onClick={() => onHowtoScreen()} className="bg-slate-50 text-xl text-slate-700 rounded-full p-3">
            <FiX className="" />
          </button>
        </div>

        <h2 className="mb-3 text-2xl font-bold text-slate-600">使い方</h2>

        <div className="mb-10 text-xl text-slate-500">
          <img src={howtoImage} alt="使い方" />
        </div>
      </div>
    </>
  );
}
