export const makeWorkbook = async function (num) {
  // const url = 'http://127.0.0.1:8787';
  const url = 'https://my-worker-production.a80dworkers.workers.dev/';

  return await fetch(url, { mode: 'cors' })
    .then((r) => r.json())
    .then((json) => {
      const datas = json['question'].split('\n').map((m) => m.split('\t'));
      const _datas = makeNoRandQuetions(num, datas); // numだけにする(ランダムなし)
      const number = json['number'];

      const suffledata = _datas.map((d) => {
        return [d[0], d[2], myShuffle(Array.from(d[1])), d[1], d[3]];
      });
      return { questions: suffledata, number: number };
    });
};

export const makeExamples = async function () {
  // const url = 'http://127.0.0.1:8787/examples';
  const url = 'https://my-worker-production.a80dworkers.workers.dev/examples';

  return await fetch(url, { mode: 'cors' })
    .then((r) => r.json())
    .then((json) => {
      json = JSON.parse(json);
      const examples = json.examples;
      // const gameNumber = json.gameNumber;
      // console.log('gameNumber:', gameNumber);
      // console.log('examples:', typeof examples[0], examples);

      return examples;
    });
};

/*
// TEST用
export const makeWorkbook = function (num) {
  const json = {
    question:
      '909\tattack\t攻撃する\t2\n2634\tplatform\tプラットフォーム，台\t3\n2087\tbeneath\t～の下に\t3\n4080\tvicious\t悪意のある，意地悪な\t5\n4085\tsalve\t軟膏，癒すもの\t5\n',
    number: '365',
  };
  const datas = json.question.split('\n').map((m) => m.split('\t'));
  const _datas = makeNoRandQuetions(num, datas); // numだけにする(ランダムなし)
  const number = json.number;

  const suffledata = _datas.map((d) => {
    return [d[0], d[2], myShuffle(Array.from(d[1])), d[1], d[3]];
  });
  return { questions: suffledata, number: number };
};

// makeExamplesのTEST用
export const makeExamples = async function () {
  let json =
    '{\n  "gameNumber": 365,\n  "examples": [\n    [\n      "1. Cyber Attack: A malicious attempt to gain unauthorized access to a computer system or network, usually with the intent to steal or damage data.",\n      "2. Physical Attack: A physical assault on a person or property, such as a robbery or vandalism.",\n      "3. Psychological Attack: A form of psychological warfare in which one person or group attempts to manipulate another person or group through psychological manipulation or intimidation."\n    ],\n    [\n      "1. A platform for online education: An online platform that provides educational resources and courses to students and teachers.",\n      "2. A platform for virtual events: A platform that allows users to host and attend virtual events such as conferences, seminars, and workshops.",\n      "3. A platform for crowdfunding: A platform that allows users to create campaigns and raise funds for their projects."\n    ],\n    [\n      "1. Beneath the surface of the ocean lies a hidden world of mysterious creatures.",\n      "2. Beneath the layers of paint, the old house revealed its original beauty.",\n      "3. Beneath the snow, the ground was still warm from the summer sun."\n    ],\n    [\n      "1. The vicious storm caused extensive damage to the town.",\n      "2. The vicious dog barked and growled at anyone who came near.",\n      "3. The vicious criminal was sentenced to life in prison."\n    ],\n    [\n      "1. A salve made from natural ingredients can be used to soothe skin irritations.",\n      "2. A salve of essential oils can be used to help relax the body and mind.",\n      "3. A salve of herbs and spices can be used to create a healing balm for sore muscles."\n    ]\n  ]\n}';
  json = JSON.parse(json);
  const examples = json.examples; //.split('\n').map((m) => m.split('\t'));
  const gameNumber = json.gameNumber;
  return examples;
};

// TEST用 文字が多い問題と答え
export const makeWorkbook_TEST = async function (num) {
  const json = {
    number: '297',
    questions: [
      [
        '3391',
        '熱狂的に',
        ['e', 'n', 't', 'h', 'u', 's', 'i', 'a', 's', 't', 'i', 'c', 'a', 'l', 'l', 'y'],
        'enthusiastically',
        '3',
      ],
      ['1449', 'アパート', ['n', 'm', 't', 'e', 'a', 't', 'r', 'a', 'p'], 'apartment', '2'],
      ['25', '～だろう，～したい，よく～したものだった', ['w', 'o', 'u', 'l', 'd'], 'would', '1'],
      [
        '3393',
        '環境的に',
        ['e', 'n', 'v', 'i', 'r', 'o', 'n', 'm', 'e', 'n', 't', 'a', 'l', 'l', 'y'],
        'environmentally',
        '1',
      ],
      ['84', '〜してもよい，～かもしれない，～であろう', ['m', 'a', 'y'], 'may', 1],
    ],
  };
  return json;
};
*/

function makeNoRandQuetions(num, qlist) {
  let questions = [];
  for (let l = 0; l < num; l += 1) {
    questions = questions.concat([qlist[l]]);
  }
  return questions;
}

export const getRandomInt = function (max) {
  return Math.floor(Math.random() * max);
};

function myShuffle(_arr) {
  const arr = _arr;
  for (let i = arr.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}
