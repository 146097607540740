import { useState } from 'react';

import './App.css';
import './components/TapTapEnglish.css';

import StartScreen from './components/StartScreen.js';
import TapTapEnglish from './components/TapTapEnglish';
import EndScreen from './components/EndScreen';
import MenuScreen from './components/MenuScreen.js';
import AboutScreen from './components/AboutScreen.js';
import HowtoScreen from './components/HowtoScreen.js';

import { makeWorkbook } from './lib/featch.mjs';

import LogoImage from './TapTapEnglish.png';
import banner300 from './image/taptapenglish_banner_70_300.jpg';
import banner500 from './image/taptapenglish_banner_70_500.jpg';

import { FiMenu } from 'react-icons/fi';
import { FiX } from 'react-icons/fi';

function App() {
  const maxquestion = 5;
  const [gameState, setGameState] = useState('start'); // state:start/game/end, words表示: words
  const [menuState, setMenuState] = useState(false); // menu
  const [aboutState, setAboutState] = useState(false); // About
  const [howtoState, setHowtoState] = useState(false); // Howto
  const [workbook, setWorkbook] = useState(''); // maxquestion
  const [number, setNumber] = useState(0); // 第０回

  async function changeState(gameState) {
    if (gameState === 'game') {
      const questions_number = await makeWorkbook(maxquestion);
      const _workbook = questions_number.questions;
      setNumber(questions_number.number);
      setWorkbook(_workbook); // ゲームのmaxquestion個の問題を作成
      // console.log("test questions_number:", questions_number);
      // console.log("test _workbook:",_workbook);
    }
    setGameState(gameState);
  }

  // Menu start
  function changeMenuState() {
    setMenuState(!menuState);
  }

  function startScreen() {
    setMenuState(false);
    setGameState('start');
    setAboutState(false);
    setHowtoState(false);
    changeMenuState();
  }

  function aboutScreen() {
    setAboutState(!aboutState);
    setHowtoState(false);
    setMenuState(false);
  }

  function howtoScreen() {
    setHowtoState(!howtoState);
    setAboutState(false);
    setMenuState(false);
  }
  // Menu end

  function setWorkbookFunc(_workbook) {
    setWorkbook(_workbook);
  }

  function Main({ gameState }) {
    // gameState
    if (gameState === 'start') {
      return <StartScreen onSelect={(gameState) => changeState(gameState)} />;
    } else if (gameState === 'game') {
      return (
        <TapTapEnglish
          maxquestion={maxquestion}
          workbook={workbook}
          setWorkbook={(workbook) => setWorkbookFunc(workbook)}
          onSelect={(gameState) => changeState(gameState)}
        />
      );
    } else if (gameState === 'end') {
      return <EndScreen number={number} workbook={workbook} onSelect={(gameState) => changeState(gameState)} />;
    }
  }

  return (
    <div className="App">
      <div id="wrapper">
        <div id="header" className="shadow-sm">
          <div className="flex justify-between title">
            <div className="flex mt-3 ">
              <img src={LogoImage} alt="" className="w-8 h-8 mt-1" />
              <h1 className="text-slate-700">TapTapEnglish</h1>
              <p className="text-lg ml-5 mt-3">― 1日5問だけの英単語 ―</p>
            </div>
            <div className="z-50 mt-4">
              <button
                name="ボタン"
                onClick={() => changeMenuState()}
                className={['p-2 text-glay-700', gameState === 'game' ? 'display-none' : ''].join(' ')}
              >
                {menuState ? <FiX className="text-2xl" /> : <FiMenu className="text-2xl" />}
              </button>
            </div>
          </div>
        </div>

        <div id="main" className="shadow-sm">
          <MenuScreen
            className={menuState ? '' : 'display-none'}
            onStartScreen={() => startScreen()}
            onAboutScreen={() => aboutScreen()}
            onHowtoScreen={() => howtoScreen()}
          />
          <AboutScreen className={aboutState ? '' : 'display-none'} onAboutScreen={() => aboutScreen()} />
          <HowtoScreen className={howtoState ? '' : 'display-none'} onHowtoScreen={() => howtoScreen()} />
          <Main gameState={gameState} />
        </div>
        <div id="bottom" className={['shadow-2xl', gameState === 'end' ? 'display-none' : ''].join(' ')}>
          <ButtomBanner />
        </div>
      </div>
    </div>
  );
}

export default App;

function ButtomBanner() {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    // console.log("width/heihgt:", width, height, width > 500);
    return { width, height };
  };

  return (
    <div>
      <img className="my-0 mx-auto" src={getWindowDimensions().width > 500 ? banner500 : banner300} alt="" />
    </div>
  );
}
