import { MdClear, MdOutlineRadioButtonUnchecked } from 'react-icons/md';

export default function Correct({ correct, className }) {
  if (correct) {
    return <MdOutlineRadioButtonUnchecked className={className} />;
  } else if (correct === false) {
    return <MdClear className={className} />;
  } else {
    return '';
  }
}
