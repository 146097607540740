import { IconButton } from './Button.js';
import Correct from './Correct';
import emoji from 'node-emoji';
import { useState, useEffect } from 'react';
import ExampleScreen from './ExampleScreen.js';
import { makeExamples } from '../lib/featch.mjs';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';

export default function EndScreen({ number, workbook, onStart = (f) => f }) {
  const solve = 5; // workbookの一番さいごの5つめが正解不正解(true/false)
  const [isShare, setShare] = useState(false);
  const [shareMassage, setShareMessage] = useState('');
  const [exampleState, setExampleState] = useState(false); // ExampleState
  const [exampleNumber, setExampleNumber] = useState(0); // exampleNumber
  const [exampleList, setExamples] = useState(''); // exampleList
  const [isExampleShow, setIsExampleShow] = useState(false); // ExampleState

  // 例題を取得
  useEffect(async () => {
    setExamples(await makeExamples());
  }, []);

  // 正解した数を返します
  const getNumberOfCorrectAnswers = () => {
    return workbook ? workbook.reduce((total, w) => (w[solve] ? total + 1 : total), 0) : '';
  };

  function copy_to_clipboard(value) {
    if (navigator.clipboard) {
      var copyText = value;
      navigator.clipboard.writeText(copyText).then(function () {
        return true;
      });
    } else {
      copy_to_clipboard_for_old_browser(value);
    }
  }

  function copy_to_clipboard_for_old_browser(value) {
    var input = document.createElement('input');
    input.style.position = 'absolute';
    input.style.opacity = 0;
    input.style.pointerEvents = 'none';
    input.value = value;

    document.body.appendChild(input);
    input.focus();
    input.setSelectionRange(0, 100);
    document.execCommand('copy');
    input.parentNode.removeChild(input);
  }

  // シェアボタン
  const share = () => {
    const gamenum = number;
    let okemoji = getOKEmoji();
    const str = `TapTapEnglish ${gamenum} ${okemoji.join('')}`;

    const emojistr = emoji.emojify(str);
    copy_to_clipboard(emojistr);
    setShareMessage(`「${emojistr}」コピーしました。`);
    setShare(true);
    setTimeout(() => {
      setShare(false);
    }, 3000);
  };

  const getOKEmoji = () => {
    if (workbook.length > 0) {
      return workbook.map((p) => (p[solve] ? ':o:' : ':x:'));
    } else {
      return '';
    }
  };

  const openExample = (exampleNumber) => {
    setIsExampleShow(true);
    setExampleNumber(exampleNumber);
    setExampleState(true);
  };

  return (
    <>
      <div id="end" className="min-w-full">
        <div className="mt-6 relative">
          {isShare && <Share shareMassage={shareMassage} />}
          <div className="text-xl text-sky-900">【 第{number}回 】</div>
          <div className="text-sky-900 text-center text-3xl -mt-5 mb-7">
            正解率 <span className="text-9xl">{Math.floor((getNumberOfCorrectAnswers() / workbook.length) * 100)}</span>
            % <span className="ml-5 text-6xl">{getNumberOfCorrectAnswers()}</span>
            <span className="text-4xl"> / {workbook.length}</span>
          </div>

          <table className="min-w-full text-2xl">
            <thead className="bg-white border-b">
              <tr>
                <th className="px-4 py-2 w-1 text-left"></th>
                <th className="px-4 py-2 w-1 text-left"></th>
                <th className="px-4 py-2 w-auto text-left">問題</th>
                <th className="px-4 py-2 w-1 text-left">lev</th>
                <th className="px-4 py-2 w-1/3 text-left">答え</th>
                <th className="px-4 py-2 w-1 text-right">例文</th>
              </tr>
            </thead>
            <tbody>
              {workbook.length > 0
                ? workbook.map((p, i) => (
                    <tr key={i} className={['border-b', Number(i) % 2 === 0 ? 'bg-gray-100' : 'bg-white'].join(' ')}>
                      <td className="px-4 py-4">{i + 1}</td>
                      <td className="px-4 py-4">
                        {
                          <Correct
                            correct={p[solve]}
                            className={['mx-auto', 'text-[2rem]', p[solve] ? 'text-sky-600' : 'text-slate-500'].join(
                              ' '
                            )}
                          />
                        }
                      </td>
                      <td className="px-4 py-4">{p[1]}</td>
                      <td className="px-4 py-4">{p[4]}</td>
                      <td className="px-4 py-4 whitespace-nowrap">{p[3]}</td>
                      <td className="px-4 py-4">
                        <button
                          className="w-12 h-12 rounded-full bg-sky-500 hover:bg-sky-700 px-3"
                          onClick={() => openExample(i)}
                        >
                          <ChevronDoubleRightIcon className="h-6 w-6 text-white" />
                        </button>
                      </td>
                    </tr>
                  ))
                : ''}
            </tbody>
          </table>

          <div className="mt-5 text-xl">
            <p>ありがとうございます&#128515;</p>
            <p>
              ChatGPTが作成した例文をご覧いただけます
              <a className="ml-2 text-sky-600" onClick={() => openExample(0)}>
                例文を見る
                <ChevronDoubleRightIcon className="mx-1 h-6 w-6 inline" />
              </a>
            </p>
          </div>
          <IconButton
            onSelect={() => share()}
            icon="HiShare"
            name="シェアする"
            className="py-2 mt-6 h-20 w-full text-3xl text-white bg-sky-500 hover:bg-sky-700 rounded-full"
          />
          <p className="text-center text-[1.5rem] mt-6 text-sky-600">
            <a href="/">
              トップページへ戻る
              <ChevronDoubleRightIcon className="mx-1 h-6 w-6 inline" />
            </a>
          </p>
        </div>
      </div>

      <footer className="endscreen">
        <p className="copyright">&copy; 2022 TapTapEnglish</p>
      </footer>

      <ExampleScreen
        exampleState={exampleState}
        setExampleState={setExampleState}
        exampleNumber={exampleNumber}
        setExampleNumber={setExampleNumber}
        isExampleShow={isExampleShow}
        setIsExampleShow={setIsExampleShow}
        exampleList={exampleList}
        workbook={workbook}
      />
    </>
  );
}

const Share = ({ shareMassage }) => <div className="snackBar">{shareMassage}</div>;
