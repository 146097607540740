import { MyButton, IconButton } from './Button.js';

export default function StartScreen({ onSelect = (f) => f }) {
  return (
    <>
      <div id="start">
        <div className="shadow shadow-cyan-500/50 p-12 w-2/3 min-w-[240px] absolute top-0 bottom-0 left-0 right-0 h-96 m-auto">
          <div>
            <p className="text-center text-[1.3rem] mb-2">１日５問だけの英単語</p>
            <h2 className="text-slate-700 font-extrabold text-6xl mb-8 leading-none">
              TapTap
              <br />
              English
            </h2>
            <p className="text-center text-[1.5rem] font-bold text-emerald-500">\ 問題はこちらから /</p>
            <IconButton
              onSelect={() => onSelect('game')}
              icon="ChevronRightIcon"
              name="START"
              className="tracking-widest pl-10 py-3 mt-2 w-full text-3xl font-bold text-white bg-sky-500 hover:bg-sky-700 rounded-full"
            />
          </div>
        </div>
      </div>
      <footer className="startscreen">
        <p className="copyright">&copy; 2022 TapTapEnglish</p>
      </footer>
    </>
  );
}
