import { getRandomInt } from '../lib/featch.mjs';
import Correct from './Correct';
import { IconButton } from './Button';

import { useState, useEffect, useRef } from 'react';

import { HiOutlineBackspace } from 'react-icons/hi';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

function TapTapEnglish({ maxquestion, workbook, setWorkbook = (f) => f, onSelect = (f) => f }) {
  /**
   * 最小スクリーンサイズ幅
   */
  const MIN_WIDTH = 260;

  /**
   * 最小スクリーンサイズ高さ
   */
  const MIN_HEIGHT = 210;

  /**
   * word間の最小距離
   */
  const MIN_POSITION = {
    small: 30,
    middle: 45,
    large: 70,
  };

  // hint
  const [handleState, setHandleState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const [isHint, setHint] = useState(false);

  const [words, setWords] = useState(false); // words表示: words
  const [qnum, setQnum] = useState(0); // 何問目

  const [answer, setAnswer] = useState(''); // 答え
  const [answerIdlist, setAnswerIdlist] = useState([]); // 答えのIDリスト（非表示・delで戻す）

  const [notice, setNotice] = useState(''); // notice（正解・不正解メッセージを表示）
  const [correct, setCorrect] = useState(); // 正解・不正解(true/false)

  // questionの文字の縦横位置をすべてきめる
  const [positionlist, setPositionlist] = useState([]);

  const wordsSize = useRef(); // id wordsのサイズ
  let rect = undefined;

  useEffect(() => {
    gameStart();
  }, []);

  // wordsSizeに依存する
  // それぞれの文字の位置を決める
  function wordsProcess(_qnum) {
    rect = wordsSize.current.getBoundingClientRect();
    if (workbook[_qnum] !== undefined && rect.width !== 0 && rect.width >= MIN_WIDTH && rect.height >= MIN_HEIGHT) {
      setNotice('');
      // console.log("文字位置を作成", workbook[_qnum][2], rect);
      let maxDistance =
        rect === undefined
          ? MIN_POSITION.small
          : rect.width * rect.height < 70000
          ? MIN_POSITION.small
          : rect.width * rect.height > 150000
          ? MIN_POSITION.large
          : MIN_POSITION.middle;
      let wordlist = false;
      while (!wordlist) {
        wordlist = wordsPosition(workbook[_qnum][2], maxDistance); // 各wordの位置を作成
        if (!wordlist) {
          maxDistance -= 5;
        }
        if (maxDistance < MIN_POSITION.small) throw new Error('wordsPosition error');
      }
      setPositionlist(wordlist);
    } else if (workbook[_qnum] !== undefined) {
      if (rect.width >= MIN_WIDTH || rect.height >= MIN_HEIGHT) {
        console.error(`scrren size error ${rect.width} ${rect.height}`);
        setNotice(`スクリーンサイズに対応しておりません。${rect.width} ${rect.height}`);
      }
    } else {
      console.error('wordsProcess error');
    }
  }

  // ゲームスタート
  const gameStart = () => {
    setPositionlist([]); // ポジションの初期化
    setAnswer(''); // 答えの初期化
    setAnswerIdlist([]);
    setNotice('');

    setQuesionNumber(0); // 初めの問題のid number
  };

  // 次の問題
  const nextQuestion = () => {
    if (qnum >= maxquestion - 1) {
      gameEnd();
      return;
    }
    setPositionlist([]); // ポジションの初期
    setAnswer(''); // 答えの初期化
    setAnswerIdlist([]);

    const newnum = qnum + 1;
    setQuesionNumber(newnum); // 次の問題id number
  };

  // ゲーム終了
  const gameEnd = () => {
    setNotice('');
    setAnswer('');
    setAnswerIdlist([]);
    setWords(false);
    onSelect('end');
  };

  // 各wordの位置を決める
  const wordsPosition = (questionWord, maxDistance) => {
    let wordlist = [];
    let e = 0; // e > 100 error
    const rectPadding = 60;
    for (let i = 0; i < questionWord.length; i += 1) {
      const newPoints = { top: getRandomInt(rect.height - rectPadding), left: getRandomInt(rect.width - rectPadding) };
      // wordの位置が他の文字と近ければもう一度位置を決める
      e += 1;
      if (e > 100) return false;
      if (!isDistanceWordlist(wordlist, newPoints, maxDistance)) {
        i -= 1;
        continue;
      }
      wordlist = wordlist.concat(newPoints);
    }
    return wordlist;
  };

  // wordlistの各wordとの距離が近ければfalse
  const isDistanceWordlist = (wordlist, n, maxDistance = 70) => {
    for (let i = 0; i < wordlist.length; i += 1) {
      const p = wordlist[i];
      const range = Math.sqrt((p.left - n.left) ** 2 + (p.top - n.top) ** 2);
      if (range < maxDistance) {
        return false;
      }
    }
    return true;
  };

  // クリックされた文字は、
  // unvisibleにする
  // answerに表示
  const clickWord = (str, num) => {
    setAnswer(answer + str);
    setAnswerIdlist([...answerIdlist, num]);
  };

  // 戻る削除
  const returnTo = () => {
    // console.log(answer.slice(-1), answer.slice(0,-2), answerIdlist, answerIdlist.slice(0,-1))
    setAnswer(answer.slice(0, -1));
    setAnswerIdlist(answerIdlist.slice(0, -1));
  };

  // 問題を表示する
  const setQuesionNumber = (num, retryCount = 0) => {
    setWords(false);
    setQnum(num);
    try {
      wordsProcess(num);
    } catch (error) {
      if (retryCount <= 5) {
        setQuesionNumber(num, retryCount + 1);
      } else {
        console.log(`error setQuesionNumber ${error}`);
      }
    }
    setTimeout(() => {
      setWords(true);
    }, 1000);
  };

  // 答え合わせ
  function checkingAnswer() {
    setWords(false);
    if (answer === workbook[qnum][3]) {
      workbook[qnum] = workbook[qnum].concat(true);
      setWorkbook(workbook);
      setCorrect(true);
      setNotice(workbook[qnum][3]);
      // console.log("正解", answer, workbook[qnum][3])
    } else {
      workbook[qnum] = workbook[qnum].concat(false);
      setWorkbook(workbook);
      setCorrect(false);
      setNotice(workbook[qnum][3]);
      // console.log("不正解", answer, workbook[qnum][3])
    }

    setTimeout(() => {
      setAnswer('');
      setCorrect();
      setNotice('');
      nextQuestion();
    }, 3000);
  }

  const { vertical, horizontal, open } = handleState;

  const handleClick = (newState) => {
    setHandleState({ open: true, ...newState });
    setTimeout(() => {
      setHandleState({ ...handleState, open: false });
    }, 2500);
  };

  // ヒントをだす
  const getHint = () => {
    handleClick({ vertical: 'top', horizontal: 'center' });
  };

  // 答えボタンの表示・非表示
  const answerButtonVisible = () => answerIdlist.length !== workbook[qnum][3].split('').length || words === false;

  // ヒント？を表示
  const visibleHint = () => {
    if (!isHint) setHint(true);
    setTimeout(() => {
      setHint(false);
    }, 2500);
  };

  return (
    <div id="game">
      <QandA workbook={workbook} qnum={qnum} answer={answer} isHint={isHint} />

      <div id="words" ref={wordsSize}>
        <div id="notice" className={notice === '' ? 'display-none' : ''}>
          <p className={['mt-1', correct ? 'display-none' : 'text-[4rem] text-red-400'].join(' ')}>{notice}</p>
          <Correct
            correct={correct}
            className={['mx-auto', 'text-[20rem]', correct ? 'text-sky-300' : 'text-slate-200'].join(' ')}
          />
        </div>
        {positionlist.length > 0 && workbook[qnum] !== undefined
          ? workbook[qnum][2].map((word, num) => {
              return (
                <Word
                  key={num}
                  position={positionlist[num]}
                  className={[
                    'wordDiv',
                    'select-none',
                    answerIdlist.includes(num) ? 'display-none' : '',
                    words ? '' : 'display-none',
                  ]}
                  callMethod={() => clickWord(word, num)}
                  word={word}
                />
              );
            })
          : ''}
        {/*{ rect !== undefined ? `${rect.width} ${rect.height} ${rect.width * rect.height}` : ""}*/}
      </div>

      <div id="controle">
        <Stack direction="row" spacing={1} className="flex justify-center">
          <Fab
            style={{ width: '32px', minWidth: '32px', height: '32px', minHeight: '32px' }}
            className="flex-none text-2xl bg-sky-100 text-slate-700 shadow-none disabled:text-slate-200 disabled:border-slate-200 disabled:bg-slate-100 rounded-full flex items-center justify-center"
            onClick={() => returnTo()}
            disabled={answer === '' || !words ? true : false}
          >
            <HiOutlineBackspace clas="w-f h-4" />
          </Fab>
          <Fab
            style={{ width: '78px', maxWidth: '78px', height: '32px', minHeight: '32px' }}
            onClick={() => visibleHint()}
            size="small"
            className="text-xl ml-2 bg-sky-100 text-slate-700 font-extrabold shadow-none disabled:text-slate-200 disabled:border-slate-200 rounded-full disabled:bg-slate-100"
            disabled={!words ? true : false}
            variant="extended"
          >
            ヒント？
          </Fab>
          <Fab
            style={{ width: '58px', minWidth: '58px', height: '32px', minHeight: '32px' }}
            onClick={() => checkingAnswer()}
            className="text-2xl ml-2 bg-sky-100 text-slate-700 font-extrabold shadow-none disabled:text-slate-200 disabled:border-slate-200 rounded-full disabled:bg-slate-100"
            disabled={!words || !answerButtonVisible() ? true : false}
            variant="extended"
          >
            次<ChevronRightIcon className="ml-1 w-8 h-8" />
          </Fab>
          <IconButton
            onSelect={() => checkingAnswer()}
            name="答える"
            className="grow text-2xl w-72 col-span-4 py-1 px-6 text-white bg-sky-500 hover:bg-sky-700 rounded-full disabled:bg-slate-200"
            disabled={workbook[qnum] !== undefined ? answerButtonVisible() : ''}
            icon="HiCheck"
            style={{ height: '32px' }}
          />
        </Stack>
      </div>
    </div>
  );
}

export default TapTapEnglish;

const Hint = ({ hint }) => <div className="snackBar">「 {hint} 」から始まります</div>;

const Word = ({ position, className, callMethod, word }) => {
  if (position) {
    return (
      <div
        onClick={() => callMethod(word)}
        className={className.join(' ')}
        style={{ position: 'absolute', top: position.top, left: position.left }}
      >
        {word}
      </div>
    );
  } else {
    throw new Error('Word Position Error');
  }
};

const QandA = ({ workbook, qnum, answer, isHint }) => {
  return (
    <div id="qa" className="relative">
      <div id="question" className="flex items-center">
        <div className="bg-sky-500 text-white h-12 w-12 text-xl tracking-normal flex justify-center items-center absolute -left-8 rounded-full">
          問{qnum + 1}
        </div>
        <div className="level">
          <span>level</span>
          {workbook[qnum][4]}
        </div>
        <div
          className={[
            'sentence text-sky-800 font-bold text-center w-full',
            workbook[qnum] === undefined ? '' : workbook[qnum][1].length <= 9 ? 'text-4xl' : 'text-2xl',
          ].join(' ')}
        >
          {workbook[qnum] !== undefined ? workbook[qnum][1] : ''}
        </div>
      </div>

      <div id="answers">{answer}</div>
      {isHint && <Hint hint={workbook[qnum][3].slice(0, 1)} />}
    </div>
  );
};
