import { GrMail } from 'react-icons/gr';
import { GrTwitter } from 'react-icons/gr';
import { FiX } from 'react-icons/fi';

export default function AboutScreen({ className, onAboutScreen = (f) => f }) {
  return (
    <>
      <div className={[className, 'z-10 bg-white absolute top-0 left-0 right-0 bottom-0 pt-28 px-8'].join(' ')}>
        <div className="text-right w-full">
          <button onClick={() => onAboutScreen()} className="bg-slate-50 text-xl text-slate-700 rounded-full p-3">
            <FiX className="" />
          </button>
        </div>

        <h2 className="mb-3 text-2xl font-bold text-slate-700">TapTapEnglishについて</h2>
        <div className="mb-10 text-xl text-slate-700">
          <ul className="mb-4 about">
            <li>
              英単語の意味が問題として出題され、散りばめ配置されたアルファベットをタップし答えることができるウェブアプリケーション
            </li>
            <li>1日5問だけの出題（毎日0時更新）</li>
            <li>出題される英単語のレベルは、中学生レベル～TOEIC900点以上までの6段階</li>
            <li>制限時間はなく、じっくりと考えられる</li>
            <li>
              出題された問題の答えが思いつかない時は、「？」ボタンを押すことでヒントとして最初の1文字目のアルファベットを表示
            </li>
            <li>出題された問題が解らない場合は、「答」ボタンで解答を表示</li>
            <li>結果をシェアボタンでシェア</li>
          </ul>
          <p className="mb-3">
            TapTapEnglishは個人で開発され運用しております。
            <br />
            連絡は下記よりお願い致します。
          </p>
          <div className="ml-6">
            <div className="flex mb-2">
              <GrMail className="mr-2" />
              taptapenglish5@gmail.com
            </div>
            <div className="flex">
              <GrTwitter className="mr-2" />
              <a href="https://mobile.twitter.com/English5Tap" target="_blank">
                @English5Tap
              </a>
            </div>
          </div>
        </div>

        <h2 className="mb-3 text-2xl font-bold text-slate-700">プライバシーポリシー</h2>
        <div className="text-xl text-slate-700">
          TapTapEnglishでは、現在Google
          Analytics等をつかった情報の収集をしておりません。変更があればTwitterでお知らせいたします。
        </div>
      </div>
    </>
  );
}
