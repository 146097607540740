import './ExampleScreen.css';

import { useEffect } from 'react';
import Fab from '@mui/material/Fab';
import { XMarkIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';

export default function ExampleScreen({
  exampleState,
  setExampleState,
  exampleNumber,
  setExampleNumber,
  isExampleShow,
  setIsExampleShow,
  exampleList,
  workbook,
}) {
  if (exampleState) {
    useEffect(async () => {
      startShowExample(exampleNumber);
    }, [exampleNumber]);

    const startShowExample = async (num) => {
      setIsExampleShow(exampleNumber);
      console.log("workbook:", num, exampleList);
      const term = workbook[num][1] ? workbook[num][3] : '';
      const japaneseTerm = workbook[num][1] ? workbook[num][1] : '';
      const examples = exampleList[num] ? exampleList[num] : '';
      const termElement = document.getElementById('term');
      while (termElement.firstChild) {
        termElement.removeChild(termElement.firstChild);
      }
      const p = document.createElement('p');
      const span = document.createElement('span');
      span.insertAdjacentHTML('beforeEnd', term);
      p.appendChild(span);
      p.insertAdjacentHTML('beforeEnd', japaneseTerm);
      termElement.appendChild(p);
      const examplesElement = document.getElementById('examples');
      // id="examples"の子要素を削除
      while (examplesElement.firstChild) {
        examplesElement.removeChild(examplesElement.firstChild);
      }
      await showExample(num, term, examplesElement, examples);
      setIsExampleShow(false);
    };

    const showExample = async (num, term, element, examples) => {
      const f = (p) => (e) => (r) => {
        p.insertAdjacentHTML('beforeEnd', e);
        return r;
      };
      await new Promise((resolve) => setTimeout(resolve, 1000)); // 2秒待つ
      return (async () => {
        for (let i = 0; i < examples.length; i++) {
          const ele = document.createElement('p');
          element.appendChild(ele);
          for (let n = 0; n < examples[i].length; n++) {
            await new Promise((resolve) => setTimeout(f(ele)(examples[i][n])(resolve), 20));
          }
          addColorInTerm(term, ele);
        }
      })();
    };

    const addColorInTerm = (term, element) => {
      const regexp = new RegExp(term, 'gi');
      const text = element.innerHTML;
      const addColorHTML = text.replace(regexp, `<span>${'$&'}</span>`);
      element.innerHTML = addColorHTML;
    };

    const next = (number) => {
      if (number < 0 || number > 5) {
        console.log('error number');
        return null;
      }
      setExampleNumber(number);
    };

    return (
      <div>
        <div id="exampleContent">
          <div id="term"></div>
          <div
            className="h-9 w-9 unvisibleExampleButton rounded-full mx-auto my-auto outline outline-slate-400 outline-1"
            onClick={() => setExampleState(false)}
          >
            <XMarkIcon className="h-9 w-9 text-slate-400" />
          </div>
          <hr />
          <div id="examples"></div>
          <div className="flex w-full justify-center">
            <Fab
              style={{ width: '120px', maxWidth: '120px', height: '32px', minHeight: '32px' }}
              onClick={() => next(exampleNumber - 1)}
              size="small"
              className="text-xl bg-sky-100 text-slate-700 font-extrabold shadow-none disabled:text-slate-200 disabled:border-slate-200 rounded-full disabled:bg-slate-100"
              disabled={typeof isExampleShow === 'number' || exampleNumber <= 0 ? true : false}
              variant="extended"
            >
              <ChevronLeftIcon className="h-10 w-10" />
              <p>前の問題</p>
            </Fab>
            <Fab
              style={{ width: '120px', maxWidth: '120px', height: '32px', minHeight: '32px' }}
              onClick={() => next(exampleNumber + 1)}
              size="small"
              className="text-xl ml-8 bg-sky-100 text-slate-700 font-extrabold shadow-none disabled:text-slate-200 disabled:border-slate-200 rounded-full disabled:bg-slate-100"
              disabled={typeof isExampleShow === 'number' || exampleNumber + 1 >= 5 ? true : false}
              variant="extended"
            >
              <p>次の問題</p>
              <ChevronRightIcon className="h-10 w-10" />
            </Fab>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
