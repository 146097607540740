export default function MenuScreen({
  className,
  onStartScreen = (f) => f,
  onAboutScreen = (f2) => f2,
  onHowtoScreen = (f3) => f3,
}) {
  return (
    <>
      <div className={[className, 'z-20 bg-white absolute top-0 left-0 right-0 bottom-0 pt-28 px-8'].join(' ')}>
        <h2 className="text-xl text-slate-500 mb-7">Menu</h2>
        <ul className="text-3xl text-slate-400">
          <li className="mb-6">
            <button className="font-bold w-full text-left hover:text-sky-500" onClick={() => onStartScreen()}>
              トップページ
            </button>
          </li>
          <li className="mb-6">
            <button className="font-bold w-full text-left hover:text-sky-500" onClick={() => onAboutScreen()}>
              TapTapEnglishについて
            </button>
          </li>
          <li className="mb-6">
            <button className="font-bold w-full text-left hover:text-sky-500" onClick={() => onHowtoScreen()}>
              使い方
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}
