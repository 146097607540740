import { HiOutlineBackspace, HiCheck, HiShare } from 'react-icons/hi';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

export const MyButton = ({ onSelect = (f) => f, name, className, disabled }) => (
  <button onClick={onSelect} className={className} disabled={disabled}>
    {name}
  </button>
);

export const IconButton = ({ onSelect = (f) => f, name, className, disabled, icon, style }) => {
  switch (icon) {
    case 'ChevronRightIcon': {
      return (
        <button onClick={onSelect} className={className} disabled={disabled} style={style} >
          {name}
          <ChevronRightIcon className="inline-block ml-3 mb-1 w-9 h-9" />
        </button>
      );
    }
    case 'HiOutlineBackspace': {
      return (
        <button onClick={onSelect} className={className} disabled={disabled} style={style} >
          <HiOutlineBackspace className="inline-block w-6 h-6" />
          {name}
        </button>
      );
    }
    case 'HiCheck': {
      return (
        <button onClick={onSelect} className={className} disabled={disabled} style={style} >
          <HiCheck className="inline-block w-6 h-6 mr-2" />
          {name}
        </button>
      );
    }
    case 'HiShare': {
      return (
        <button onClick={onSelect} className={className} disabled={disabled} style={style} >
          <HiShare className="inline-block w-6 h-6 mr-2" />
          {name}
        </button>
      );
    }
    default: {
      return;
    }
  }
};
